@font-face {
    font-family: "becket";
    src: url('./txixtassets/BecketLight.ttf') format('truetype');
}

:root {
    --vw: 1vw;
    --vh: 1vh;
}

* {
    font-family: 'becket', monospace;
    font-size: large;
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    position: relative;
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.onecentered-bg {
    position: relative;
    width: 5%;
    height: 25%;
    background-color: black;
    box-shadow: 0 0 calc(15 * var(--vw)) calc(20 * var(--vh)) black;
    -webkit-box-shadow: 0 0 80 black;
}

.logo {
    width: 270px;
    height: 270px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer; 
    animation: dropAndShrink 2s 3s forwards; /* Start after 3 seconds, take 2 seconds to complete */
    filter: drop-shadow(0px 0px 6px rgba(114, 107, 255, 0.9)); 
}

#logo-img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
}

.header {
    opacity: 0;
    animation: fadeIn 2s forwards 3s;
    display: flex;
    align-items: top;
    justify-content: space-between;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    padding: 0 10px;
}

.left-cell,
.right-cell {
    flex: 1;
    display: flex;
}

.left-cell {
    justify-content: flex-end;
    padding-right: 11px;
    text-align: right;
    color: gray;
}

.right-cell {
    justify-content: flex-start;
    padding-left: 11px;
    padding-top: 4px;
    position: relative;
}

p {
    margin: 0;
}

.menu-overlay {
    position: absolute;
    top: 0;
    left: 50%; 
    transform: translateX(-50%); 
    width: 150px;
    height: 55px;
    background-color: transparent; 
    cursor: pointer;
    z-index: 20;
}

.hamburger .icon { 
    /* if > 5 images, stack these differently */
    width: 51px;
    height: 2px;
    margin-bottom: 5px;
    transition: transform 1s, height 1s;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 6px rgba(114, 107, 255, 0.9);
}

.hamburger .icon img {
    width: 100%;
    vertical-align: top; /* Remove potential space below images */
}

.hamburger .icon span {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.5s, left 0.5s 0.5s;
    pointer-events: none;
    /* color: rgba(147, 141, 255, 0.9); */
    color: white;
}

.hamburger.active .icon span {
    left: 60px; /* Position title 60px to the right of the icon */
    opacity: 1;
    transition: opacity 0.5s 1s;
}

.hamburger.active .icon {
    margin-bottom: 22px;
    padding-right: 75px;
    height: 51px;
    overflow: visible;
    box-shadow: none;
}

.hamburger.active .icon:nth-child(1) {
    transform: translateY(150px);
}

.hamburger.active .icon:nth-child(2) {
    transform: translateY(170px);
}

.hamburger.active .icon:nth-child(3) {
    transform: translateY(190px);
}

.hamburger.active .icon:nth-child(4) {
    transform: translateY(210px);
}

.hamburger.active .icon:nth-child(5) {
    transform: translateY(230px);
}

.hamburger.active .icon:after {
    left: 60px; /* Position title 60px to the right of the icon */
    opacity: 1;
    overflow: visible;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    width: 80%; 
    height: 80%; 
    box-shadow: 0px 0px 15px rgb(114, 107, 255);
    border-radius: 5px;
    overflow: hidden;
    flex-direction: column;
    border: none;
    border-width: none;
    outline: none;
}

.modal-body {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.close-span {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: right;
    text-align: right;
    padding: 15px;
}

.close-btn {
    background: none;
    border: none;
    font-size: x-large;
    color: white;
    text-shadow: 0px 0px 3px rgb(114, 107, 255);
    cursor: pointer;
}

.modal-icon-title {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 10px 66px 10px;
}

.modal-icon-title h3 {
    font-size: x-large;
    color:rgb(170, 166, 255);
    text-shadow: 0px 0px 3px rgb(114, 107, 255);
}

.modal-icon {
    width: 100px;
    height: 100px;
}

.modal-content {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0 0 calc(11 * var(--vw)) calc(11 * var(--vh)) white;
    color: black;
    padding: 10px;
    margin: 33px;
}

.modal-content p {
    font-size: medium;
    max-height: 300px; 
    overflow-y: auto;
}

.modal-content a {
    padding-top: 15px;
    text-decoration: none;
    color: black;
    text-shadow: 0 0 3px rgb(114, 107, 255);
}

.modal-link {
    padding-top: 15px;
}

.contact-text {
    height: 200px;  
    width: 100%;
    border-radius: 3px;
    font-size: medium;
    padding: 5px;
    border: none;
    resize: none;
    box-shadow: 0 0 calc(10 * var(--vw)) calc(5 * var(--vh)) black;
}

.contact-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10;
}

.contact-buttons a {
    font-size: large;
    color: white;
    text-decoration: none;
    text-shadow: 0 0 5px rgb(114, 107, 255);
}

/* Dev use to view modal */
.modal {
    display: flex;
}

@keyframes dropAndShrink {
    to {
        width: 135px;
        height: 135px;
        top: 90%;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media screen and (max-height: 400px) {
    * {
        font-size: x-small;
    }

    .hamburger .icon {
        width: 20px;
    }
    
    .hamburger.active .icon span {
        left: 25px; 
    }

    .hamburger.active .icon {
        height: 20px;
    }

    .hamburger.active .icon:nth-child(1) {
        transform: translateY(40px);
    }
    
    .hamburger.active .icon:nth-child(2) {
        transform: translateY(60px);
    }
    
    .hamburger.active .icon:nth-child(3) {
        transform: translateY(80px);
    }
    
    .hamburger.active .icon:nth-child(4) {
        transform: translateY(100px);
    }
    
    .hamburger.active .icon:nth-child(5) {
        transform: translateY(120px);
    }
    
    .modal-icon {
        width: 25px;
        height: 25px;
    }

    .modal-content {
        padding: 5px;
        margin: 11px;
    }
}

@media screen and (max-height: 580px) {
    * {
        font-size: small;
    }

    .hamburger .icon {
        width: 25px;
    }
    
    .hamburger.active .icon span {
        left: 30px; 
    }

    .hamburger.active .icon {
        height: 25px;
    }
    
    .modal-icon {
        width: 50px;
        height: 50px;
    }

    .modal-content {
        padding: 5px;
        margin: 11px;
    }

    .modal-content p {
        font-size: small;
        max-height: 250px;
    }
}

@media screen and (max-height: 700px) {
    * {
        font-size: small;
    }

    .hamburger.active .icon:nth-child(1) {
        transform: translateY(100px);
    }
    
    .hamburger.active .icon:nth-child(2) {
        transform: translateY(147px);
    }
    
    .hamburger.active .icon:nth-child(3) {
        transform: translateY(194px);
    }
    
    .hamburger.active .icon:nth-child(4) {
        transform: translateY(241px);
    }
    
    .hamburger.active .icon:nth-child(5) {
        transform: translateY(288px);
    }

    .close-span {
        padding: 5px;
    }
    
    .modal-icon-title h3 {
        font-size: large;
    }
    
    .modal-icon {
        width: 50px;
        height: 50px;
    }

    .modal-content {
        padding: 5px;
        margin: 11px;
    }

    .modal-content p {
        font-size: small;
        max-height: 250px;
    }
}

@media screen and (min-width: 1300px) {
    .onecentered-bg {
        width: 25%;
        height: 5%;
        box-shadow: 0 0 calc(40 * var(--vw)) calc(35 * var(--vh)) black;
    }   
}

@media screen and (min-height: 750px) {
    .onecentered-bg {
        width: 5%;
        height: 5%;
        box-shadow: 0 0 calc(30 * var(--vw)) calc(32 * var(--vh)) black;
    }
    
    .left-cell {
        color: gray;
    }
    .hamburger.active .icon:nth-child(1) {
        transform: translateY(230px);
    }
    
    .hamburger.active .icon:nth-child(2) {
        transform: translateY(330px);
    }
    
    .hamburger.active .icon:nth-child(3) {
        transform: translateY(430px);
    }
    
    .hamburger.active .icon:nth-child(4) {
        transform: translateY(530px);
    }
    
    .hamburger.active .icon:nth-child(5) {
        transform: translateY(630px);
    }

    .contact-text {
        height: 100px;
    }
}


@media screen and (min-height: 950px) {
    .onecentered-bg {
        width: 5%;
        height: 5%;
        box-shadow: 0 0 calc(35 * var(--vw)) calc(30 * var(--vh)) black;
    }
    
    .left-cell {
        color: gray;
    }
    .hamburger.active .icon:nth-child(1) {
        transform: translateY(310px);
    }
    
    .hamburger.active .icon:nth-child(2) {
        transform: translateY(410px);
    }
    
    .hamburger.active .icon:nth-child(3) {
        transform: translateY(510px);
    }
    
    .hamburger.active .icon:nth-child(4) {
        transform: translateY(610px);
    }
    
    .hamburger.active .icon:nth-child(5) {
        transform: translateY(710px);
    }

    .contact-text {
        height: 100px;
    }
}

@media screen and (min-height: 1350px) {
    .onecentered-bg {
        width: 5%;
        height: 5%;
        box-shadow: 0 0 calc(35 * var(--vw)) calc(30 * var(--vh)) black;
    }
    
    .left-cell {
        color: gray;
    }

    .hamburger.active .icon {
        padding-bottom: 10px;
    }

    .hamburger.active .icon:nth-child(1) {
        transform: translateY(450px);
    }
    
    .hamburger.active .icon:nth-child(2) {
        transform: translateY(550px);
    }
    
    .hamburger.active .icon:nth-child(3) {
        transform: translateY(650px);
    }
    
    .hamburger.active .icon:nth-child(4) {
        transform: translateY(750px);
    }
    
    .hamburger.active .icon:nth-child(5) {
        transform: translateY(850px);
    }

    .contact-text {
        height: 100px;
    }
}

@media screen and (min-width: 1400px) {
    .modal-content-papi {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .modal-content {
        box-shadow: 0 0 calc(4 * var(--vw)) calc(10 * var(--vh)) white;
    }

    .modal-content p {
        font-size: medium;
        max-width: 500px;
    }
}

@media screen and (min-width: 1000px) {
    .onecentered-bg {
        width: 25%;
        height: 5%;
        box-shadow: 0 0 calc(40 * var(--vw)) calc(35 * var(--vh)) black;
    }   

    .contact-text {
        height: 200px;
        width: 500px;
    }
}

/* @media screen and (min-width: 1300px) {
    .onecentered-bg {
        width: 25%;
        height: 5%;
        box-shadow: 0 0 calc(40 * var(--vw)) calc(35 * var(--vh)) black;
    }   
} */